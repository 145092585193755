import type { CanActivateFn, Route } from '@angular/router';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { adminRole } from '../constants/admin-role';

export const adminGuard = (redirectRoute: Route) => {
  const resp: CanActivateFn = () => {
    const router = inject(Router);
    const keycloakService = inject(KeycloakService);

    const isAdmin = keycloakService.isUserInRole(adminRole);

    if (!isAdmin) {
      router.navigate([redirectRoute.path], {
        skipLocationChange: true,
      });
      return false;
    }

    return true;
  };
  return resp;
};
