export const environment = {
  production: true,
  enableServiceWorker: false,
  apiUrl: 'https://ti-api.cpit.app',
  keycloakConfig: {
    url: 'https://auth.cpit.app/',
    realm: 'cpit',
    clientId: 'cpit-tarifingress-frontend',
  },
  stageWarning: {
    icon: '',
    message: '',
  },
};
